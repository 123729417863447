// Getters
export const USER = 'account/USER'
export const ABILITIES = 'account/ABILITIES'
export const IS_HUNTER = 'account/IS_HUNTER'
export const IS_CLOSER = 'account/IS_CLOSER'
export const IS_PLANNER = 'account/IS_PLANNER'
export const IS_PNS = 'account/IS_PNS' // PNS = Hunter, Closer e FA+
export const COMPANY_PENDENCIES = 'account/COMPANY_PENDENCIES'
export const SYSTEM_VERSION_NOTIFICATIONS = 'account/SYSTEM_VERSION_NOTIFICATIONS'

// Mutations
export const MUTATE_TOKEN = 'account/MUTATE_TOKEN'
export const MUTATE_TOKEN_LOGOUT = 'account/MUTATE_TOKEN_LOGOUT'
export const MUTATE_COMPANY_PENDENCIES = 'account/MUTATE_COMPANY_PENDENCIES'
export const MUTATE_SYSTEM_VERSION_NOTIFICATIONS = 'account/MUTATE_SYSTEM_VERSION_NOTIFICATIONS'

// Actions
export const GET_USER_DATA_CREDENTIALS = 'account/GET_USER_DATA_CREDENTIALS'
export const LOGIN = 'account/LOGIN'
export const GET_USER_DATA = 'account/GET_USER_DATA'
export const LOGOUT = 'account/LOGOUT'
export const GET_CUSTOMER_CREDENTIALS = 'account/GET_CUSTOMER_CREDENTIALS'
export const SAVE_DARK_MODE = 'account/SAVE_DARK_MODE'
export const CHECK_CONTRACT_REPORT_PENDENCIES = 'account/CHECK_CONTRACT_REPORT_PENDENCIES'
export const CHECK_COMPANY_PENDENCIES = 'account/CHECK_COMPANY_PENDENCIES'
export const CHECK_APPOINTMENT_REPORT_PENDENCIES = 'account/CHECK_APPOINTMENT_REPORT_PENDENCIES'
export const CHECK_SYSTEM_VERSION_NOTIFICATIONS = 'account/CHECK_SYSTEM_VERSION_NOTIFICATIONS'
export const RECOVER_PASSWORD = 'account/RECOVER_PASSWORD'
export const SET_NEW_PASSWORD = 'account/SET_NEW_PASSWORD'
export const GET_APPIFY_LINK = 'account/GET_APPIFY_LINK'
export const READ_SYSTEM_NOTIFICATION = 'account/READ_SYSTEM_NOTIFICATION'