<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Nome" label-for="name">
            <b-form-input
              id="name"
              v-model="name"
              :disabled="!createPartnerCompanyEnabled
                && !updatePartnerCompanyEnabled"
              :class="{ 'is-invalid': v$.name.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.name.required.$invalid">
                Você deve informar um nome
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="CNPJ" label-for="cnpj">
            <b-form-input
              id="cnpj"
              v-mask="'##.###.###/####-##'"
              v-model="cnpj"
              :disabled="!createPartnerCompanyEnabled
                && !updatePartnerCompanyEnabled"
              :class="{ 'is-invalid': v$.cnpj.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.cnpj.valid.$invalid">
                Você deve informar um CNPJ válido
              </span>
            </div>
          </b-form-group>
        </b-col>
        <div style="width: 100%" v-for="(agent, index) in agents" :key="index">
          <b-col cols="12" md="6" class="d-inline-block">
            <b-form-group
              label="Representante"
              :label-for="`agent-name-${index}`"
            >
              <b-form-input
              :id="`agent-name-${index}`"
              v-model="agent.name"
              :disabled="!createPartnerCompanyEnabled
                && !updatePartnerCompanyEnabled"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="d-inline-block">
            <b-form-group
              label="Tipo do Representante"
              :label-for="`agent-type-${index}`"
            >
              <v-select
                :id="`agent-type-${index}`"
                v-model="agent.type"
                :reduce="agentType => agentType.id"
                :options="agentTypes"
                label="name"
                dir="ltr"
                :disabled="!createPartnerCompanyEnabled
                  && !updatePartnerCompanyEnabled"
                :class="getSelectErrorClass(v$.agents.$each.$response.$data[index].type.$error)"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
              <div class="invalid-feedback">
                <span v-if="
                  v$.agents.$dirty && 
                    (v$.agents.$each.$response.$data[index].type.$invalid || 
                    v$.agents.$each.$response.$data[index].type.required.$invalid)
                ">
                  Você deve informar o tipo do representante
                </span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="d-inline-block">
            <b-form-group
              label="E-mail"
              v-bind:label-for="`e-mail-agent-${index}`"
            >
              <b-form-input
                :id="`e-mail-agent-${index}`"
                v-model="agent.email"
                :disabled="!createPartnerCompanyEnabled
                && !updatePartnerCompanyEnabled"
                :class="{
                  'is-invalid':
                    v$.agents.$dirty && 
                    (v$.agents.$each.$response.$data[index].email.$invalid || 
                    v$.agents.$each.$response.$data[index].email.required.$invalid)
                }"
              />
              <div class="invalid-feedback">
                <span v-if="
                  v$.agents.$dirty && 
                  (v$.agents.$each.$response.$data[index].email.$invalid || 
                  v$.agents.$each.$response.$data[index].email.required.$invalid)
                ">
                  Você deve informar um e-mail válido
                </span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="d-inline-block">
            <b-form-group
              label="Telefone"
              v-bind:label-for="`agent-phone-${index}`"
            >
            <vue-tel-input
              v-bind:id="`agent-phone-${index}`"
              v-model="agent.phone"
              placeholder="teste"
              :disabled="!createPartnerCompanyEnabled
              && !updatePartnerCompanyEnabled"
              :class="{
                'is-invalid':
                  (v$.agents.$each.$response.$data[index].phone.required.$invalid ||
                  v$.agents.$each.$response.$data[index].phone.$invalid) &&
                  v$.agents.$dirty 
              }"
            />
              <div class="invalid-feedback">
                <span v-if="
                  v$.agents.$dirty &&
                  (v$.agents.$each.$response.$data[index].phone.required.$invalid ||
                  v$.agents.$each.$response.$data[index].phone.$invalid)
                ">
                  Você deve informar um telefone válido
                </span>
              </div>
            </b-form-group>
          </b-col>
        </div>
        <b-col cols="12">
          <template
            v-if="createPartnerCompanyEnabled"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="float-left"
              @click="addAgent"
            >
              Novo Representante
            </b-button>
          </template>
          <template
            v-if="agents.length > 1
              && deletePartnerCompanyEnabled"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="float-right"
              @click="dropAgent"
            >
              Remover Representante
            </b-button>
          </template>
        </b-col>
        <b-col cols="12">
          <br><br>
          <h2>Produtos</h2>
          <b-row>
            <b-col cols="12" md="6" class="d-inline-block">
              <template v-for="(product, index) in products" >
                <div v-if="index < products.length/2" :key="`product-${product.id}`">
                  <b-form-checkbox
                    class="d-inline-block"
                    v-model="companyProducts"
                    :value="product.id"
                    :disabled="!createPartnerCompanyEnabled
                      && !updatePartnerCompanyEnabled"
                    switch
                  />
                  <span class="d-inline-block">{{ product.name }}</span>
                  <br><br>
                </div>
              </template>
            </b-col>
            <b-col cols="12" md="6" class="d-inline-block">
              <template v-for="(product, index) in products" >
                <div v-if="index >= products.length/2" :key="`product-${product.id}`" >
                  <b-form-checkbox
                    class="d-inline-block"
                    v-model="companyProducts"
                    :value="product.id"
                    :disabled="!createPartnerCompanyEnabled
                      && !updatePartnerCompanyEnabled"
                    switch
                  />
                  <span class="d-inline-block">{{ product.name }}</span>
                  <br><br>
                </div>
              </template>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-row class="justify-content-end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'companies-list' }"
              :disabled="saving"
            >
              Cancelar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mx-1"
              :disabled="loading || saving ||
                (!createPartnerCompanyEnabled
                  && !updatePartnerCompanyEnabled)"
              @click.prevent="save"
            >
              <b-spinner v-if="saving" small />
              <feather-icon v-else icon="SaveIcon" class="mr-50" />
              <span>
                {{ saving ? "Salvando..." : "Salvar" }}
              </span>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import cnpjIsValid from '../../../validators/cnpj'
import {onlyDigits} from '../../../helpers/converters'
import { required, email, helpers, requiredIf } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { getVueSelectErrorClass } from '@/helpers/validators'
import * as types from '../store/types'
import { CREATE_ACTION, UPDATE_ACTION, DELETE_ACTION, PARTNER_COMPANY_RESOURCE } from '@/constants/resources'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() }
  },
  data() {
    return {
      loading: false,
      saving: false,
      name: undefined,
      cnpj: undefined,
      companyProducts: [],
      agents: [{ name: '', type: '', email: '', phone: '' }],
    }
  },
  validations() {
    return {
      name: { required },
      cnpj: {
        required,
        valid(value) {
          return cnpjIsValid(value)
        },
      },
      companyProducts: {},
      agents:{
        $each: helpers.forEach({
          name:{},
          type:{required: requiredIf((value, agent) => {
            return agent.name && agent.name !== ''
          })},
          email:{email, required: requiredIf((value, agent) => {
            return agent.name && agent.name !== ''
          })},
          phone:{
            required: requiredIf((value, agent) => {
              return agent.name && agent.name !== ''
            }),
            minLength (value) {
              return !value || (value.length <= 18 && value.length >= 10 && value.startsWith('+'))
            }
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      agentTypes: types.PARTNER_COMPANY_AGENT_TYPES,
      products: types.PRODUCTS
    }),
    createPartnerCompanyEnabled: function() {
      return this.$can(CREATE_ACTION, PARTNER_COMPANY_RESOURCE)
    },
    updatePartnerCompanyEnabled: function() {
      return this.$can(UPDATE_ACTION, PARTNER_COMPANY_RESOURCE)
    },
    deletePartnerCompanyEnabled: function() {
      return this.$can(DELETE_ACTION, PARTNER_COMPANY_RESOURCE)
    },
  },
  mounted() {
    this.getAgentTypes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao carregar os tipos de agentes para seleção. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.getAllProducts()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao carregar a lista de produtos para seleção. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    if (this.$route.params.id) {
      this.loading = true
      this.getPartnerCompany(this.$route.params.id)
        .then(response => {
          const { name, doc_cnpj, products, agents } = response.data
          this.name = name
          this.cnpj = doc_cnpj
          this.companyProducts = products.map((val) => {
            return val.id
          })
          if(agents.length > 0){
            this.agents = agents
          }
          this.v$.$touch()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao carregar a empresa selecionada. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  methods: {
    ...mapActions({
      saveProduct: types.SAVE_PARTNER_COMPANY,
      getPartnerCompany: types.GET_PARTNER_COMPANY,
      getAllProducts: types.GET_ALL_PRODUCTS,
      getAgentTypes: types.GET_PARTNER_COMPANY_AGENT_TYPES
    }),
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.saving = true
      const { name, cnpj, companyProducts, agents } = this
    
      let payload = {
        id: this.$route.params.id,
        name,
        doc_cnpj: onlyDigits(cnpj),
        products: companyProducts,
        agents: _.map(
          agents,
          (agent) => {
            const{
              name,
              type,
              email,
              phone
            } = agent
            return {
              name,
              type,
              email,
              phone: onlyDigits(phone)
            }
          })
      }
      this.saveProduct(payload)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              text: `A empresa foi ${
                this.$route.params.id ? 'atualizada' : 'criada'
              } com sucesso`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'companies-list' })
        })
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: error?.response?.data?.message || 'Ocorreu um erro ao salvar a empresa. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError)
    },
    addAgent(){
      this.agents.push({ name: '', type: '', email: '', phone: '' })
    },
    dropAgent(){
      if(this.agents.length > 1){
        this.agents.pop()
      }
    }
  },
}
</script>
