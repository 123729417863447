<template>
  <div>
    <b-sidebar
      id="sidebar-edit-contract"
      sidebar-class="sidebar-lg"
      :visible="newDnaCampaignSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateNewDnaCampaignSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Adicionar nova Campanha de DNA</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <template v-if="loading.form">
          <b-row class="justify-content-md-center mt-2">
            <b-spinner
              big
              variant="primary"
            />
          </b-row>
        </template>
        <template v-else> 
          <b-form
            class="p-2"
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Missão Aprendiz"
                  label-for="apprentice"
                >
                  <b-form-input
                    id="apprentice"
                    v-model="apprentice"
                    type="number"
                    min="0"
                    max="100"
                    :class="{ 'is-invalid': v$.apprentice.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.apprentice.minValue.$invalid || v$.apprentice.maxValue.$invalid">
                      O valor deve estar entre 0 e 100.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Missão Focado"
                  label-for="focused"
                >
                  <b-form-input
                    id="focused"
                    v-model="focused"
                    type="number"
                    min="0"
                    max="100"
                    :class="{ 'is-invalid': v$.focused.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.focused.minValue.$invalid || v$.focused.maxValue.$invalid">
                      O valor deve estar entre 0 e 100.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Missão Protagonisca"
                  label-for="protagonist"
                >
                  <b-form-input
                    id="protagonist"
                    v-model="protagonist"
                    type="number"
                    min="0"
                    max="100"
                    :class="{ 'is-invalid': v$.protagonist.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.protagonist.minValue.$invalid || v$.protagonist.maxValue.$invalid">
                      O valor deve estar entre 0 e 100.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Missão Campeão"
                  label-for="champion"
                >
                  <b-form-input
                    id="champion"
                    v-model="champion"
                    type="number"
                    min="0"
                    max="50"
                    :class="{ 'is-invalid': v$.champion.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.champion.minValue.$invalid || v$.champion.maxValue.$invalid">
                      O valor deve estar entre 0 e 50.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group label-for="certified-pnhunter">
                  <b-form-checkbox
                    id="certified-pnhunter"
                    v-model="certifiedPnhunter"
                    :class="{ 'is-invalid': v$.certifiedPnhunter.$error }"
                  >
                    Certificado de PNHunter
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group label-for="certified-pnclouser">
                  <b-form-checkbox
                    id="certified-pnclouser"
                    v-model="certifiedPnclouser"
                    :class="{ 'is-invalid': v$.certifiedPnclouser.$error }"
                  >
                    Certificado de PNClouser
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group label-for="certified-p1">
                  <b-form-checkbox
                    id="certified-p1"
                    v-model="certifiedP1"
                  >
                    Certificado P1
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group label-for="certified-p2">
                  <b-form-checkbox
                    id="certified-p2"
                    v-model="certifiedP2"
                  >
                    Certificado P2
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group label-for="certified-p3">
                  <b-form-checkbox
                    id="certified-p3"
                    v-model="certifiedP3"
                  >
                    Certificado P3
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group label-for="certified-p4">
                  <b-form-checkbox
                    id="certified-p4"
                    v-model="certifiedP4"
                  >
                    Certificado P4
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group label-for="certified-p5">
                  <b-form-checkbox
                    id="certified-p5"
                    v-model="certifiedP5"
                  >
                    Certificado P5
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Presença na reunião semanal 'Franqueados/PNG'"
                  label-for="meetingPresence"
                >
                  <b-form-input
                    id="meetingPresence"
                    v-model="meetingPresence"
                    type="number"
                    min="0"
                    max="10"
                    :class="{ 'is-invalid': v$.meetingPresence.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.meetingPresence.minValue.$invalid || v$.meetingPresence.maxValue.$invalid">
                      O valor deve estar entre 0 e 10.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Presença na reunião mensal 'Franqueados/Acionista'"
                  label-for="monthlyMeetingPresence"
                >
                  <b-form-input
                    id="monthlyMeetingPresence"
                    v-model="monthlyMeetingPresence"
                    type="number"
                    min="0"
                    max="25"
                    :class="{ 'is-invalid': v$.monthlyMeetingPresence.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.monthlyMeetingPresence.minValue.$invalid || v$.monthlyMeetingPresence.maxValue.$invalid">
                      O valor deve estar entre 0 e 25.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="PEC - Programa de estudo contínuo"
                  label-for="pec"
                >
                  <b-form-input
                    id="pec"
                    v-model="pec"
                    type="number"
                    min="0"
                    max="100"
                    :class="{ 'is-invalid': v$.pec.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.pec.minValue.$invalid || v$.pec.maxValue.$invalid">
                      O valor deve estar entre 0 e 100.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Certificados externos"
                  label-for="externalCertificate"
                >
                  <b-form-input
                    id="externalCertificate"
                    v-model="externalCertificate"
                    type="number"
                    min="0"
                    max="100"
                    :class="{ 'is-invalid': v$.externalCertificate.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.externalCertificate.minValue.$invalid || v$.externalCertificate.maxValue.$invalid">
                      O valor deve estar entre 0 e 100.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Média do time 4 WS parceiros do mês assistidos"
                  label-for="teamAverage4WsPartners"
                >
                  <b-form-input
                    id="teamAverage4WsPartners"
                    v-model="teamAverage4WsPartners"
                    type="number"
                    min="0"
                    max="100"
                    :class="{ 'is-invalid': v$.teamAverage4WsPartners.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.teamAverage4WsPartners.minValue.$invalid || v$.teamAverage4WsPartners.maxValue.$invalid">
                      O valor deve estar entre 0 e 100.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Aprendizagem continuada"
                  label-for="continuousLearning"
                >
                  <b-form-input
                    id="continuousLearning"
                    v-model="continuousLearning"
                    type="number"
                    min="0"
                    max="100"
                    :class="{ 'is-invalid': v$.continuousLearning.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.continuousLearning.minValue.$invalid || v$.continuousLearning.maxValue.$invalid">
                      O valor deve estar entre 0 e 100.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Média do time 4 WS"
                  label-for="averageTeam4Ws"
                >
                  <b-form-input
                    id="averageTeam4Ws"
                    v-model="averageTeam4Ws"
                    type="number"
                    min="0"
                    max="100"
                    :class="{ 'is-invalid': v$.averageTeam4Ws.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.averageTeam4Ws.minValue.$invalid || v$.averageTeam4Ws.maxValue.$invalid">
                      O valor deve estar entre 0 e 100.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Débitos"
                  label-for="debits"
                >
                  <b-form-input
                    id="debits"
                    v-model="debits"
                    type="number"
                    min="0"
                    max="100"
                    :class="{ 'is-invalid': v$.debits.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.debits.minValue.$invalid || v$.debits.maxValue.$invalid">
                      O valor deve estar entre 0 e 100.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="PN's responderem o CSAT"
                  label-for="pnsRespondedCsat"
                >
                  <b-form-input
                    id="pnsRespondedCsat"
                    v-model="pnsRespondedCsat"
                    type="number"
                    min="0"
                    max="100"
                    :class="{ 'is-invalid': v$.pnsRespondedCsat.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.pnsRespondedCsat.minValue.$invalid || v$.pnsRespondedCsat.maxValue.$invalid">
                      O valor deve estar entre 0 e 100.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="PN's aprovados no WS do CEO"
                  label-for="pnsApprovedWsCeo"
                >
                  <b-form-input
                    id="pnsApprovedWsCeo"
                    v-model="pnsApprovedWsCeo"
                    type="number"
                    min="0"
                    max="150"
                    :class="{ 'is-invalid': v$.pnsApprovedWsCeo.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.pnsApprovedWsCeo.minValue.$invalid || v$.pnsApprovedWsCeo.maxValue.$invalid">
                      O valor deve estar entre 0 e 150.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="8 REC de CL/EF"
                  label-for="recClEf"
                >
                  <b-form-input
                    id="recClEf"
                    v-model="recClEf"
                    type="number"
                    min="0"
                    max="15"
                    :class="{ 'is-invalid': v$.recClEf.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.recClEf.minValue.$invalid || v$.recClEf.maxValue.$invalid">
                      O valor deve estar entre 0 e 15.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="3 REC de PN/EF"
                  label-for="recPnEf"
                >
                  <b-form-input
                    id="recPnEf"
                    v-model="recPnEf"
                    type="number"
                    min="0"
                    max="15"
                    :class="{ 'is-invalid': v$.recPnEf.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.recPnEf.minValue.$invalid || v$.recPnEf.maxValue.$invalid">
                      O valor deve estar entre 0 e 15.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="90% de CSAT do treinamento prático"
                  label-for="csat_training_score"
                >
                  <b-form-input
                    id="csat_training_score"
                    v-model="csatTrainingScore"
                    type="number"
                    min="0"
                    max="100"
                    :class="{ 'is-invalid': v$.csatTrainingScore.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.csatTrainingScore.minValue.$invalid || v$.csatTrainingScore.maxValue.$invalid">
                      O valor deve estar entre 0 e 100.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Média dos PNs no Protagonista acima de 60"
                  label-for="protagonist_pn_average"
                >
                  <b-form-input
                    id="protagonist_pn_average"
                    v-model="protagonistPnAverage"
                    type="number"
                    min="0"
                    max="100"
                    :class="{ 'is-invalid': v$.protagonistPnAverage.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.protagonistPnAverage.minValue.$invalid || v$.protagonistPnAverage.maxValue.$invalid">
                      O valor deve estar entre 0 e 100.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Média dos PNs no Protagonista acima de 60"
                  label-for="collaboration_behavior_score"
                >
                  <b-form-input
                    id="collaboration_behavior_score"
                    v-model="collaborationBehaviorScore"
                    type="number"
                    min="0"
                    max="100"
                    :class="{ 'is-invalid': v$.collaborationBehaviorScore.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.collaborationBehaviorScore.minValue.$invalid || v$.collaborationBehaviorScore.maxValue.$invalid">
                      O valor deve estar entre 0 e 100.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="85 de NPS de cliente"
                  label-for="customer_nps_score"
                >
                  <b-form-input
                    id="customer_nps_score"
                    v-model="customerNpsScore"
                    type="number"
                    min="0"
                    max="250"
                    :class="{ 'is-invalid': v$.customerNpsScore.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.customerNpsScore.minValue.$invalid || v$.customerNpsScore.maxValue.$invalid">
                      O valor deve estar entre 0 e 250.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="70% do NPS de cliente enviado com resposta"
                  label-for="customer_nps_sent_percentage"
                >
                  <b-form-input
                    id="customer_nps_sent_percentage"
                    v-model="customerNpsSentPercentage"
                    type="number"
                    min="0"
                    max="200"
                    :class="{ 'is-invalid': v$.customerNpsSentPercentage.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.customerNpsSentPercentage.minValue.$invalid || v$.customerNpsSentPercentage.maxValue.$invalid">
                      O valor deve estar entre 0 e 200.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="d-flex m-2 justify-content-between">
              <b-button
                :disabled="saving"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="hide"
                class="lead-buttons"
              >
                fechar
              </b-button>
              <b-button
                :disabled="saving"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="lead-buttons"
                type="submit"
              >
                {{ saving ? "Salvando..." : "Salvar" }}
              </b-button>
            </div>
          </b-form>
        </template>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import { minValue, maxValue } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import vSelect from 'vue-select'
import { VMoney } from 'v-money'
import { getVueSelectErrorClass } from '@/helpers/validators'
import * as types from '../store/types'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  data() {
    return {
      saving: false,
      loading: {
        form: false,
      },
      maxPointsFocused: undefined,
      maxPointsProtagonist: undefined,
      apprentice: undefined,
      focused: undefined,
      protagonist: undefined,
      champion: undefined,
      certifiedPnhunter: undefined,
      certifiedPnclouser: undefined,
      certifiedP1: undefined,
      certifiedP2: undefined,
      certifiedP3: undefined,
      certifiedP4: undefined,
      certifiedP5: undefined,
      meetingPresence: undefined,
      monthlyMeetingPresence: undefined,
      pec: undefined,
      externalCertificate: undefined,
      teamAverage4WsPartners: undefined,
      continuousLearning: undefined,
      averageTeam4Ws: undefined,
      debits: undefined,
      pnsRespondedCsat: undefined,
      pnsApprovedWsCeo: undefined,
      recClEf: undefined,
      recPnEf: undefined,
      csatTrainingScore: undefined,
      protagonistPnAverage: undefined,
      collaborationBehaviorScore: undefined,
      customerNpsScore: undefined,
      customerNpsSentPercentage: undefined
    }
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() }
  },
  computed: {
    ...mapGetters({
      newDnaCampaignSidebar: types.NEW_DNA_CAMPAIGN_SIDEBAR,
    })
  },
  validations() {
    return {
      apprentice: { minValue: minValue(0), maxValue: maxValue(100) },
      focused: { minValue: minValue(0), maxValue: maxValue(100) },
      protagonist: { minValue: minValue(0), maxValue: maxValue(100) },
      champion: { minValue: minValue(0), maxValue: maxValue(50) },
      certifiedPnhunter: {},
      certifiedPnclouser: {},
      certifiedP1: {},
      certifiedP2: {},
      certifiedP3: {},
      certifiedP4: {},
      certifiedP5: {},
      meetingPresence: { minValue: minValue(0), maxValue: maxValue(10) },
      monthlyMeetingPresence: { minValue: minValue(0), maxValue: maxValue(25) },
      pec: { minValue: minValue(0), maxValue: maxValue(100) },
      externalCertificate: { minValue: minValue(0), maxValue: maxValue(100) },
      teamAverage4WsPartners: { minValue: minValue(0), maxValue: maxValue(100) },
      continuousLearning: { minValue: minValue(0), maxValue: maxValue(100) },
      averageTeam4Ws: { minValue: minValue(0), maxValue: maxValue(100) },
      debits: { minValue: minValue(0), maxValue: maxValue(100) },
      pnsRespondedCsat: { minValue: minValue(0), maxValue: maxValue(100) },
      pnsApprovedWsCeo: { minValue: minValue(0), maxValue: maxValue(150) },
      recClEf: { minValue: minValue(0), maxValue: maxValue(15) },
      recPnEf: { minValue: minValue(0), maxValue: maxValue(15) },
      csatTrainingScore: { minValue: minValue(0), maxValue: maxValue(100) },
      protagonistPnAverage: { minValue: minValue(0), maxValue: maxValue(100) },
      collaborationBehaviorScore: { minValue: minValue(0), maxValue: maxValue(100) },
      customerNpsScore: { minValue: minValue(0), maxValue: maxValue(250) },
      customerNpsSentPercentage: { minValue: minValue(0), maxValue: maxValue(200) },
    }
  },
  methods: {
    ...mapMutations({
      mutateNewDnaCampaignSidebar: types.MUTATE_NEW_DNA_CAMPAIGN_SIDEBAR,
    }),
    ...mapActions({
      getDnaCampaign: types.GET_DNA_CAMPAIGN,
      saveDnaCampaign: types.SAVE_DNA_CAMPAIGN,
    }),
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError)
    },
    onShow() {
      this.loading.form = true
      this.getDnaCampaign({
        consultant_id: this.newDnaCampaignSidebar.consultant_id,
      })
        .then(response => {
          if (response && response.data) {
            const data = response.data
            this.apprentice = data.apprentice
            this.focused = data.focused
            this.protagonist = data.protagonist
            this.champion = data.champion
            this.certifiedPnhunter = data.certified_pnhunter
            this.certifiedPnclouser = data.certifiedPnclouser
            this.certifiedP1 = data.certified_p1
            this.certifiedP2 = data.certified_p2
            this.certifiedP3 = data.certified_p3
            this.certifiedP4 = data.certified_p4
            this.certifiedP5 = data.certified_p5
            this.meetingPresence = data.meeting_presence
            this.monthlyMeetingPresence = data.monthly_meeting_presence
            this.pec = data.pec
            this.externalCertificate = data.external_certificate
            this.teamAverage4WsPartners = data.team_average_4_ws_partners
            this.continuousLearning = data.continuous_learning
            this.averageTeam4Ws = data.average_team_4_ws
            this.debits = data.debits
            this.pnsRespondedCsat = data.pns_responded_csat
            this.pnsApprovedWsCeo = data.pns_approved_ws_ceo
            this.recClEf = data.rec_cl_ef
            this.recPnEf = data.rec_pn_ef
            this.csatTrainingScore = data.csat_training_score
            this.protagonistPnAverage = data.protagonist_pn_average
            this.collaborationBehaviorScore = data.collaboration_behavior_score
            this.customerNpsScore = data.customer_nps_score
            this.customerNpsSentPercentage = data.customer_nps_sent_percentage
          }
        })
        .catch(() => {
          this.clear()
        })
        .finally(() => {
          this.loading.form = false
        })
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.saving = true
      const {
        apprentice,
        focused,
        protagonist,
        champion,
        certifiedPnhunter,
        certifiedPnclouser,
        certifiedP1,
        certifiedP2,
        certifiedP3,
        certifiedP4,
        certifiedP5,
        meetingPresence,
        monthlyMeetingPresence,
        pec,
        externalCertificate,
        teamAverage4WsPartners,
        continuousLearning,
        averageTeam4Ws,
        debits,
        pnsRespondedCsat,
        pnsApprovedWsCeo,
        recClEf,
        recPnEf,
        csatTrainingScore,
        protagonistPnAverage,
        collaborationBehaviorScore,
        customerNpsScore,
        customerNpsSentPercentage,
      } = this
      this.saveDnaCampaign({
        consultant_id: this.newDnaCampaignSidebar.consultant_id,
        apprentice,
        focused,
        protagonist,
        champion,
        certified_pnhunter: certifiedPnhunter,
        certified_pnclouser: certifiedPnclouser,
        certified_p1: certifiedP1,
        certified_p2: certifiedP2,
        certified_p3: certifiedP3,
        certified_p4: certifiedP4,
        certified_p5: certifiedP5,
        meeting_presence: meetingPresence,
        monthly_meeting_presence: monthlyMeetingPresence,
        pec,
        external_certificate: externalCertificate,
        team_average_4_ws_partners: teamAverage4WsPartners,
        continuous_learning: continuousLearning,
        average_team_4_ws: averageTeam4Ws,
        debits,
        pns_responded_csat: pnsRespondedCsat,
        pns_approved_ws_ceo: pnsApprovedWsCeo,
        rec_cl_ef: recClEf,
        rec_pn_ef: recPnEf,
        csat_training_score: csatTrainingScore,
        protagonist_pn_average: protagonistPnAverage,
        collaboration_behavior_score: collaborationBehaviorScore,
        customer_nps_score: customerNpsScore,
        customer_nps_sent_percentage: customerNpsSentPercentage,
      })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso!',
              text: 'Campanha salva com sucesso.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutateNewDnaCampaignSidebar({ visible: false })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao salvar a campanha. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
    clear() {
      this.apprentice = undefined
      this.focused = undefined
      this.protagonist = undefined
      this.champion = undefined
      this.certifiedPnhunter = false
      this.certifiedPnclouser = false
      this.certifiedP1 = false
      this.certifiedP2 = false
      this.certifiedP3 = false
      this.certifiedP4 = false
      this.certifiedP5 = false
      this.meetingPresence = undefined
      this.monthlyMeetingPresence = undefined
      this.pec = undefined
      this.externalCertificate = undefined
      this.teamAverage4WsPartners = undefined
      this.continuousLearning = undefined
      this.averageTeam4Ws = undefined
      this.debits = undefined
      this.pnsRespondedCsat = undefined
      this.pnsApprovedWsCeo = undefined
      this.recClEf = undefined
      this.recPnEf = undefined
      this.csatTrainingScore = undefined
      this.protagonistPnAverage = undefined
      this.collaborationBehaviorScore = undefined
      this.customerNpsScore = undefined
      this.customerNpsSentPercentage = undefined
      this.mutateNewDnaCampaignSidebar({ id: undefined })
      this.v$.$reset()
    },
  },
}
</script>

<style lang="scss">
.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
.lead-buttons {
  width: 10rem;
}
</style>
