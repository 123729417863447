import * as types from './types'

export default {
  [types.CONSULTANTS_SEARCH_RESULT]: state => {
    return state.searchResult
  },
  [types.DNA_HISTORICAL_SEARCH_RESULT]: state => {
    return state.searchResultDnaHistorical
  },
  [types.IMPORT_SEARCH_RESULT]: state => {
    return state.searchResultImports
  },
  [types.IMPORT_HISTORY_SEARCH_RESULT]: state => {
    return state.searchResultImportHistory
  },
  [types.DETAILS_GENERAL_INFORMATION]: state => {
    return state.details.generalInformation
  },
  [types.DETAILS_COMPANY_DATA]: state => {
    return state.details.companyData
  },
  [types.DETAILS_NETWORK_DATA]: state => {
    return state.details.networkData
  },
  [types.DIRECT_TEAM]: state => {
    return state.details.directTeam
  },
  [types.NEW_CONSULTANT_SIDEBAR]: state => {
    return state.newConsultantSidebar
  },
  [types.TRANSFERRED_CONSULTANTS]: state => {
    return state.transferredConsultants
  },
  [types.TRANSFERABLE_CONSULTANTS]: state => {
    return state.transferableConsultants
  },
  [types.CHANGE_CONSULTANT_ROLE_SIDEBAR]: state => {
    return state.changeConsultantRoleSidebar
  },
  [types.AFFILIATION_TYPES]: state => {
    return state.affiliationTypes
  },
  [types.NEW_DNA_CAMPAIGN_SIDEBAR]: state => {
    return state.newDnaCampaignSidebar
  },
  [types.IMPORT_EAD_SIDEBAR]: state => {
    return state.importEadSidebar
  },
}
