var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"name"}},[_c('b-form-input',{class:{ 'is-invalid': _vm.v$.name.$error },attrs:{"id":"name","disabled":!_vm.createPartnerCompanyEnabled
              && !_vm.updatePartnerCompanyEnabled},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('div',{staticClass:"invalid-feedback"},[(_vm.v$.name.required.$invalid)?_c('span',[_vm._v(" Você deve informar um nome ")]):_vm._e()])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"CNPJ","label-for":"cnpj"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],class:{ 'is-invalid': _vm.v$.cnpj.$error },attrs:{"id":"cnpj","disabled":!_vm.createPartnerCompanyEnabled
              && !_vm.updatePartnerCompanyEnabled},model:{value:(_vm.cnpj),callback:function ($$v) {_vm.cnpj=$$v},expression:"cnpj"}}),_c('div',{staticClass:"invalid-feedback"},[(_vm.v$.cnpj.valid.$invalid)?_c('span',[_vm._v(" Você deve informar um CNPJ válido ")]):_vm._e()])],1)],1),_vm._l((_vm.agents),function(agent,index){return _c('div',{key:index,staticStyle:{"width":"100%"}},[_c('b-col',{staticClass:"d-inline-block",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Representante","label-for":("agent-name-" + index)}},[_c('b-form-input',{attrs:{"id":("agent-name-" + index),"disabled":!_vm.createPartnerCompanyEnabled
              && !_vm.updatePartnerCompanyEnabled},model:{value:(agent.name),callback:function ($$v) {_vm.$set(agent, "name", $$v)},expression:"agent.name"}})],1)],1),_c('b-col',{staticClass:"d-inline-block",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo do Representante","label-for":("agent-type-" + index)}},[_c('v-select',{class:_vm.getSelectErrorClass(_vm.v$.agents.$each.$response.$data[index].type.$error),attrs:{"id":("agent-type-" + index),"reduce":function (agentType) { return agentType.id; },"options":_vm.agentTypes,"label":"name","dir":"ltr","disabled":!_vm.createPartnerCompanyEnabled
                && !_vm.updatePartnerCompanyEnabled},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                var search = ref.search;
                var searching = ref.searching;
                var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(agent.type),callback:function ($$v) {_vm.$set(agent, "type", $$v)},expression:"agent.type"}}),_c('div',{staticClass:"invalid-feedback"},[(
                _vm.v$.agents.$dirty && 
                  (_vm.v$.agents.$each.$response.$data[index].type.$invalid || 
                  _vm.v$.agents.$each.$response.$data[index].type.required.$invalid)
              )?_c('span',[_vm._v(" Você deve informar o tipo do representante ")]):_vm._e()])],1)],1),_c('b-col',{staticClass:"d-inline-block",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"E-mail","label-for":("e-mail-agent-" + index)}},[_c('b-form-input',{class:{
                'is-invalid':
                  _vm.v$.agents.$dirty && 
                  (_vm.v$.agents.$each.$response.$data[index].email.$invalid || 
                  _vm.v$.agents.$each.$response.$data[index].email.required.$invalid)
              },attrs:{"id":("e-mail-agent-" + index),"disabled":!_vm.createPartnerCompanyEnabled
              && !_vm.updatePartnerCompanyEnabled},model:{value:(agent.email),callback:function ($$v) {_vm.$set(agent, "email", $$v)},expression:"agent.email"}}),_c('div',{staticClass:"invalid-feedback"},[(
                _vm.v$.agents.$dirty && 
                (_vm.v$.agents.$each.$response.$data[index].email.$invalid || 
                _vm.v$.agents.$each.$response.$data[index].email.required.$invalid)
              )?_c('span',[_vm._v(" Você deve informar um e-mail válido ")]):_vm._e()])],1)],1),_c('b-col',{staticClass:"d-inline-block",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Telefone","label-for":("agent-phone-" + index)}},[_c('vue-tel-input',{class:{
              'is-invalid':
                (_vm.v$.agents.$each.$response.$data[index].phone.required.$invalid ||
                _vm.v$.agents.$each.$response.$data[index].phone.$invalid) &&
                _vm.v$.agents.$dirty 
            },attrs:{"id":("agent-phone-" + index),"placeholder":"teste","disabled":!_vm.createPartnerCompanyEnabled
            && !_vm.updatePartnerCompanyEnabled},model:{value:(agent.phone),callback:function ($$v) {_vm.$set(agent, "phone", $$v)},expression:"agent.phone"}}),_c('div',{staticClass:"invalid-feedback"},[(
                _vm.v$.agents.$dirty &&
                (_vm.v$.agents.$each.$response.$data[index].phone.required.$invalid ||
                _vm.v$.agents.$each.$response.$data[index].phone.$invalid)
              )?_c('span',[_vm._v(" Você deve informar um telefone válido ")]):_vm._e()])],1)],1)],1)}),_c('b-col',{attrs:{"cols":"12"}},[(_vm.createPartnerCompanyEnabled)?[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-left",attrs:{"variant":"primary"},on:{"click":_vm.addAgent}},[_vm._v(" Novo Representante ")])]:_vm._e(),(_vm.agents.length > 1
            && _vm.deletePartnerCompanyEnabled)?[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"variant":"outline-secondary"},on:{"click":_vm.dropAgent}},[_vm._v(" Remover Representante ")])]:_vm._e()],2),_c('b-col',{attrs:{"cols":"12"}},[_c('br'),_c('br'),_c('h2',[_vm._v("Produtos")]),_c('b-row',[_c('b-col',{staticClass:"d-inline-block",attrs:{"cols":"12","md":"6"}},[_vm._l((_vm.products),function(product,index){return [(index < _vm.products.length/2)?_c('div',{key:("product-" + (product.id))},[_c('b-form-checkbox',{staticClass:"d-inline-block",attrs:{"value":product.id,"disabled":!_vm.createPartnerCompanyEnabled
                    && !_vm.updatePartnerCompanyEnabled,"switch":""},model:{value:(_vm.companyProducts),callback:function ($$v) {_vm.companyProducts=$$v},expression:"companyProducts"}}),_c('span',{staticClass:"d-inline-block"},[_vm._v(_vm._s(product.name))]),_c('br'),_c('br')],1):_vm._e()]})],2),_c('b-col',{staticClass:"d-inline-block",attrs:{"cols":"12","md":"6"}},[_vm._l((_vm.products),function(product,index){return [(index >= _vm.products.length/2)?_c('div',{key:("product-" + (product.id))},[_c('b-form-checkbox',{staticClass:"d-inline-block",attrs:{"value":product.id,"disabled":!_vm.createPartnerCompanyEnabled
                    && !_vm.updatePartnerCompanyEnabled,"switch":""},model:{value:(_vm.companyProducts),callback:function ($$v) {_vm.companyProducts=$$v},expression:"companyProducts"}}),_c('span',{staticClass:"d-inline-block"},[_vm._v(_vm._s(product.name))]),_c('br'),_c('br')],1):_vm._e()]})],2)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary","to":{ name: 'companies-list' },"disabled":_vm.saving}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mx-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.loading || _vm.saving ||
              (!_vm.createPartnerCompanyEnabled
                && !_vm.updatePartnerCompanyEnabled)},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[(_vm.saving)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.saving ? "Salvando..." : "Salvar")+" ")])],1)],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }