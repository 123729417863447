<template>
  <div>
    <single-file-upload-handler-sidebar />
    <edit-contract-sidebar />
    <add-receipt-sidebar />
    <query-asaas-identifier-sidebar />
    <request-deletion-sidebar />
    <edit-snapshot-sidebar />
    <comment-sidebar />
    <edit-contract-owner-sidebar />

    <!-- Filters -->
    <contracts-list-filters
      :spinner-active="loading"
      ref="filters"
      :search="search"
    />
    <contracts-totalizer
      v-if="searched"
      :totalizers="totalizers"
      :aggroupments="aggroupments"
      :filterStatus="filterByStatus"
    />
    <!-- Table Container Card -->
    <b-card v-if="searched" no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <!-- Search -->
          <b-col v-if="canCreateContract" cols="12" md="6">
            <b-row class="d-flex align-items-center justify-content-end">
              <div class="mx-1" cols="12" md="6">
                <b-button variant="primary" :to="{ name: 'contract-save' }">
                  <feather-icon icon="PlusCircleIcon" class="mr-50" />
                  <span class="text-nowrap">Novo contrato</span>
                </b-button>
              </div>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontrados produtos para esta busca"
        :busy="loading"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
        @row-clicked="goToContractDetails"
      >
        <!-- Column: status -->
        <template #cell(contract_status_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.contract_status_name }}
            </span>
          </div>
        </template>
        <!-- Column: Dias -->
        <template #cell(status_date)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.status_date }}
            </span>
          </div>
        </template>
        <!-- Column: PN -->
        <template #cell(consultant_name)="data">
          <div class="text-wrap">
            <template v-if="data.item.consultant_name">
              <b-button
                :id="`consultant-pop-icon-${data.item.id}`"
                class="btn-icon"
                variant="flat"
                style="padding: 0"
              >
                <feather-icon
                  :id="`consultant-pop-icon-${data.item.id}`"
                  size="16"
                  icon="ChevronsUpIcon"
                  class="cursor-pointer center"
                />
              </b-button>
              <user-detail-popover
                :target="`consultant-pop-icon-${data.item.id}`"
                type="consultant"
                :id="data.item.id_consultant"
                :showDetailUrl="true"
                :showWhastapp="true"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.consultant_name }}
              </span>
            </template>
          </div>
        </template>
        <!-- Column: Cliente -->
        <template #cell(customer_name)="data">
          <div class="text-wrap">
            <template v-if="data.item.customer_name">
              <b-button
                :id="`customer-pop-icon-${data.item.id}`"
                class="btn-icon"
                variant="flat"
                style="padding: 0"
              >
                <feather-icon
                  size="16"
                  icon="ChevronsUpIcon"
                  class="cursor-pointer"
                />
              </b-button>
              <user-detail-popover
                :target="`customer-pop-icon-${data.item.id}`"
                type="customer"
                :id="data.item.id_customer"
                :showDetailUrl="true"
                :showWhastapp="true"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.customer_name }}
              </span>
            </template>
          </div>
        </template>
        <!-- Column: solução financeira -->
        <template #cell(financial_solution_name)="data">
          <div class="text-wrap">
            <template v-if="data.item.financial_solution_name">
              <b-button
                :id="`financial-solution-pop-icon-${data.item.id}`"
                class="btn-icon"
                variant="flat"
                style="padding: 0"
              >
                <feather-icon
                  size="16"
                  icon="ChevronsUpIcon"
                  class="cursor-pointer"
                />
              </b-button>
              <financial-solution-popover
                :target="`financial-solution-pop-icon-${data.item.id}`"
                :id="data.item.financial_solution_id"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.financial_solution_name }}
              </span>
            </template>
          </div>
        </template>
        <!-- Column: Ltv Bruto -->
        <template #cell(gross_value)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.gross_value | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Prêmio mensal -->
        <template #cell(monthly_premium)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.monthly_premium | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Data do report -->
        <template #cell(contract_date)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.contract_date | onlyDate
            }}</span>
          </div>
        </template>
        <!-- Column: Data de assinatura  -->
        <template #cell(signed_at)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.signed_at | onlyDate
            }}</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret right boundary="window">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="data.item.consultant_status !== consultantStatus.ACTIVE"
              @click="updateOwner(data.item.id)"
            >
              <feather-icon icon="Edit3Icon" />
              <span class="align-middle ml-50">Editar dono do contrato</span>
            </b-dropdown-item>
            <b-dropdown-item @click="detailsNewTab(data.item.id)">
              <feather-icon icon="ExternalLinkIcon" />
              <span class="align-middle ml-50">Detalhes do contrato</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="showDownload(data.item)"
              @click="downloadContrato(data.item.id)"
            >
              <feather-icon icon="FileIcon" />
              <span class="align-middle ml-50">Baixar contrato</span>
            </b-dropdown-item>            
            <b-dropdown-item
              v-if="canUpdateContract"
              @click="editContract(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <template v-if="canImportPayments">
              <b-dropdown-item @click="addReceipt(data.item)">
                <feather-icon icon="DollarSignIcon" />
                <span class="align-middle ml-50">Adicionar recebimento</span>
              </b-dropdown-item>

              <b-dropdown-item @click="queryAsaasId">
                <feather-icon icon="SearchIcon" />
                <span class="align-middle ml-50"
                  >Consultar identificador Asaas</span
                >
              </b-dropdown-item>
            </template>
            <template v-if="showBtDelete(data.item.id_contract_status) && canDeleteContract">
              <b-dropdown-item @click="requestDeletion(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Excluir</span>
              </b-dropdown-item>
            </template>
            <template v-if="canUpdateContract">
              <b-dropdown-item @click="editSnapshot(data.item)">
                <feather-icon icon="PieChartIcon" />
                <span class="align-middle ml-50">Editar snapshot</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  isAssinatura(data.item.financial_solution_id) ||
                  isEP(data.item.financial_solution_id)
                "
                @click="updatePayments(data.item.id)"
              >
                <feather-icon icon="RefreshCcwIcon" />
                <span class="align-middle ml-50"
                  >Sincronizar pagamentos do contrato</span
                >
              </b-dropdown-item>
            </template>
            <b-dropdown-item @click="openFileChooser(data.item.id)">
              <feather-icon icon="PaperclipIcon" />
              <span class="align-middle ml-50">Anexar arquivo</span>
            </b-dropdown-item>
            <b-dropdown-item @click="addComment(data.item.id)">
              <feather-icon icon="MessageSquareIcon" />
              <span class="align-middle ml-50">Adicionar comentário</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canUpdateContract"
              @click="resetDaysStatus(data.item.id)"
            >
              <feather-icon icon="RefreshCcwIcon" />
              <span class="align-middle ml-50">Reiniciar contador de dias</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canUpdateImportedPayments"
              @click="reversalPayments(data.item.id)"
            >
              <feather-icon icon="RotateCcwIcon" />
              <span class="align-middle ml-50">Estornar comissões</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              :value="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import { onlyDate, floatToMoney } from "@/helpers/converters";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  CREATE_ACTION,
  UPDATE_ACTION,
  CONTRACT_RESOURCE,
  PAYMENT_IMPORT,
  DELETE_ACTION,
} from "@/constants/resources";
import ContractsListFilters from "../components/ContractsListFilters";
import ContractsTotalizer from "../components/ContractsTotalizer";
import FinancialSolutionPopover from "../components/FinancialSolutionPopover";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as accountTypes from "@/modules/account/store/types";
import UserDetailPopover from "@/modules/shared/components/UserDetailPopover";
import SingleFileUploadHandlerSidebar from "@/modules/shared/components/SingleFileUploadHandlerSidebar";
import EditContractSidebar from "../components/EditContractSidebar";
import AddReceiptSidebar from "../components/AddReceiptSidebar";
import QueryAsaasIdentifierSidebar from "../components/QueryAsaasIdentifierSidebar";
import { onlyDigits } from "../../../helpers/converters";
import RequestDeletionSidebar from "../components/RequestDeletionSidebar";
import EditSnapshotSidebar from "../components/EditSnapshotSidebar";
import CommentSidebar from "@/modules/shared/components/CommentSidebar";
import EditContractOwnerSidebar from "../components/EditContractOwnerSidebar";
import { getRangeDates } from "@/helpers/date_picker";
import * as financial from "@/constants/financial";
import * as consultantStatus from "@/constants/consultant_status";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    ContractsListFilters,
    UserDetailPopover,
    ContractsTotalizer,
    FinancialSolutionPopover,
    SingleFileUploadHandlerSidebar,
    EditContractSidebar,
    AddReceiptSidebar,
    QueryAsaasIdentifierSidebar,
    RequestDeletionSidebar,
    EditSnapshotSidebar,
    CommentSidebar,
    EditContractOwnerSidebar,
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      consultantStatus,
      loading: false,
      exporting: false,
      tableColumns: [
        { key: "contract_status_name", label: "Status", sortable: true },
        { key: "status_date", label: "Dias", sortable: true },
        { key: "consultant_name", label: "PN", sortable: true },
        { key: "customer_name", label: "Cliente", sortable: true },
        {
          key: "financial_solution_name",
          label: "Solução Financeira",
          sortable: true,
        },
        { key: "gross_value", label: "LTV Bruto", sortable: true },
        { key: "monthly_premium", label: "Prêmio Mensal", sortable: true },
        { key: "contract_date", label: "Data do contrato", sortable: true },
        { key: "signed_at", label: "Data da assinatura", sortable: true },
        { key: "actions", label: "Ações" },
      ],
      order: undefined,
      sortField: null,
      currentPage: 1,
      itemsPerPage: 1,
      searched: false,
    };
  },
  computed: {
    ...mapGetters({
      contractSearchResult: types.CONTRACTS_SEARCH_RESULT,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      user: accountTypes.USER,
    }),
    canCreateContract: function () {
      return this.$can(CREATE_ACTION, CONTRACT_RESOURCE);
    },
    canUpdateContract: function () {
      return this.$can(UPDATE_ACTION, CONTRACT_RESOURCE);
    },
    canDeleteContract: function () {
      return this.$can(DELETE_ACTION, CONTRACT_RESOURCE);
    },
    canImportPayments: function () {
      return this.$can(CREATE_ACTION, PAYMENT_IMPORT);
    },
    canUpdateImportedPayments: function () {
      return this.$can(UPDATE_ACTION, PAYMENT_IMPORT);
    },
    items: function () {
      return this.contractSearchResult?.data || [];
    },
    totalItems: function () {
      return this.contractSearchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.contractSearchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.contractSearchResult?.to || 0;
    },
    totalizers: function () {
      return this.contractSearchResult?.totalizers || [];
    },
    aggroupments: function () {
      return this.contractSearchResult?.totalizers_aggroupments || [];
    }
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
  },
  methods: {
    ...mapActions({
      searchContract: types.SEARCH_CONTRACTS,
      exportContracts: types.EXPORT_CONTRACTS,
      attachContractFile: types.SAVE_ATTACHMENT,
      openFileUploadSidebar: sharedTypes.OPEN_FILE_UPLOAD_SIDEBAR,
      openEditContract: types.OPEN_EDIT_CONTRACT_SIDEBAR,
      openAddReceiptSidebar: types.OPEN_ADD_RECEIPT_SIDEBAR,
      openQueryAsaasIdSidebar: types.OPEN_QUERY_ASAAS_ID_SIDEBAR,
      openRequestDeletionSidebar: types.OPEN_REQUEST_DELETION_SIDEBAR,
      openEditSnapshot: types.OPEN_EDIT_SNAPSHOT_SIDEBAR,
      updateContractPayments: types.UPDATE_PAYMENTS,
      openCommentSidebar: sharedTypes.OPEN_COMMENT_SIDEBAR,
      saveComment: types.SAVE_COMMENT,
      resetDaysOnStatus: types.RESET_DAYS_ON_STATUS,
      reversalContractPayments: types.REVERSAL_CONTRACT_PAYMENTS,
      openEditOwnerSidebar: types.OPEN_EDIT_CONTRACT_OWNER_SIDEBAR,
      downloadOnContract: types.DOWNLOAD_ON_CONTRACT,
    }),
    isEP(financial_solution_id) {
      return financial_solution_id == financial.EP;
    },
    isAssinatura(financial_solution_id) {
      return (
        financial_solution_id == financial.ASSINATURA_SUPORTE ||
        financial_solution_id == financial.ACOMPANHAMENTO_BASICO ||
        financial_solution_id == financial.ACOMPANHAMENTO_ESSENCIAL ||
        financial_solution_id == financial.ACOMPANHAMENTO_ELITE || 
        financial_solution_id == financial.GESTAO_FINANCEIRA
      );
    },
    showDownload(contract){
      return contract.has_autentique && contract.signed_at;
    },
    downloadContrato(id) {
      this.loading = true;

      this.downloadOnContract({
        id: id,
      })
        .then((response) => {
          if (response.data.contract_url) {
            setTimeout(() => {
              window.open(response.data.contract_url, "_blank").focus();
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao baixar o contrato. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao baixar o contrato. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToContractDetails(contract, index) {
      this.$router.push({
        name: "contract-details-summary",
        params: { id: contract.id },
      });
    },
    openFileChooser(contractId) {
      this.openFileUploadSidebar({
        title: "Anexo do contrato",
        description: `Escolha um documento para anexar ao contrato Nº ${contractId}`,
        id: contractId,
        saveAction: this.attachContractFile,
      });
    },
    editContract(contract) {
      const {
        id,
        proposal_number,
        id_contract_status,
        financial_solution_id,
        createdAt,
        signed_at,
        paid_at,
        deployed_at,
      } = contract;
      this.openEditContract({
        id,
        proposal_number,
        id_contract_status,
        financial_solution_id,
        createdAt: this.getDateString(createdAt),
        signed_at: this.getDateString(signed_at),
        paid_at: this.getDateString(paid_at),
        deployed_at: this.getDateString(deployed_at),
        currentPage: this.currentPage ? this.currentPage : 1,
        saveAction: this.search,
      });
    },
    getDateString(date) {
      return onlyDate(date);
    },
    addReceipt(contract) {
      this.openAddReceiptSidebar({
        id: contract.id,
        currentPage: this.currentPage ? this.currentPage : 1,
        saveAction: this.search,
      });
    },
    queryAsaasId() {
      this.openQueryAsaasIdSidebar();
    },
    requestDeletion(contractId) {
      this.openRequestDeletionSidebar({
        id: contractId,
        currentPage: this.currentPage ? this.currentPage : 1,
        saveAction: this.search,
      });
    },
    editSnapshot(contract) {
      this.openEditSnapshot({
        id: contract.id,
        consultant_id: contract.id_consultant,
      });
    },
    addComment(contractId) {
      this.openCommentSidebar({
        id: contractId,
        title: "Adicionar comentário ao contrato " + contractId,
        saveAction: this.saveComment,
      });
    },
    showBtDelete(contract_status) {
      if (contract_status == "8" || contract_status == "16") {
        return false;
      } else {
        return true;
      }
    },
    updatePayments(contractId) {
      this.updateContractPayments(contractId)
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "O contrato foi sincronizado com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          if (err.response.status == 405) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text:
                  "Não é possivel sincronizar o contrato. " +
                  err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao sincronizar o contrato. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
    filterByStatus(status) {
      this.$refs.filters.status = status;
      this.search(1);
    },
    search(currentPage) {
      this.searched = true;
      this.loading = true;
      this.currentPage = currentPage;
      const {
        customer,
        customerCPF,
        contractId,
        status,
        contractDate,
        initialPaymentDate,
        endPaymentDate,
        franchise,
        consultant,
        daysOnStatus,
        product,
        partnerCompany,
        financialSolution,
        structureOption,
        partnerStructureOption,
      } = this.$refs.filters;
      const contract_date = getRangeDates(contractDate);
      this.searchContract({
        customer,
        customerCPF: onlyDigits(customerCPF),
        contractId,
        status,
        initialDate: contract_date.start,
        endDate: contract_date.end,
        initialPaymentDate,
        endPaymentDate,
        franchise,
        consultant,
        daysOnStatus,
        product,
        partnerCompany,
        financialSolution,
        structureOption,
        partnerStructureOption,
        order: this.order,
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage) ? currentPage : 1,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os contratos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exportData() {
      this.exporting = true;
      const {
        customer,
        customerCPF,
        status,
        contractDate,
        initialPaymentDate,
        endPaymentDate,
        franchise,
        consultant,
        daysOnStatus,
        product,
        partnerCompany,
        financialSolution,
        structureOption,
        partnerStructureOption,
      } = this.$refs.filters;
      const contract_date = getRangeDates(contractDate);
      this.exportContracts({
        customer,
        customerCPF: onlyDigits(customerCPF),
        status,
        initialDate: contract_date.start,
        endDate: contract_date.end,
        initialPaymentDate,
        endPaymentDate,
        franchise,
        consultant,
        daysOnStatus,
        product,
        partnerCompany,
        financialSolution,
        structureOption,
        partnerStructureOption,
        order: this.order,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os contratos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.exporting = false;
        });
    },
    sort(event) {
      //clear in third click
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? " DESC" : " ASC";
        this.order = event.sortBy + direction;
        this.sortField = event.sortBy;
      }
      this.search();
    },
    detailsNewTab(id) {
      var routeData = this.$router.resolve({
        name: "contract-details-summary",
        params: { id },
      });
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      });
    },
    resetDaysStatus(id) {
      this.resetDaysOnStatus({ id })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Os dias foram reiciados com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao reiniciar os dias. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.search(this.currentPage);
        });
    },
    reversalPayments(id) {
      this.$swal({
        title: "Tem certeza?",
        text: `Deseja estornar todas as comissões do contrato? A operação não poderá ser desfeita.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.reversalContractPayments({ id })
            .then((response) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: "Todas as comissões do contrato foram estornadas.",
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao estornar as comissões. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.search(this.currentPage);
            });
        }
      });
    },
    updateOwner(contractId) {
      this.openEditOwnerSidebar({
        id: contractId,
        saveAction: this.search,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
