<template>
  <div>
    <b-sidebar
        id="sidebar-new-campaign-kpi"
        sidebar-class="sidebar-lg"
        :visible="newCampaignKpiSidebar.visible"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        @change="(val) => mutateNewCampaignKpiSidebar({ visible: val })"
        @hidden="clear"
        @shown="onShow"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>{{ newCampaignKpiSidebar.id ? "Editar campanha" : "Nova campanha" }}</h4>
          <div>
            <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
        >
          <b-form-group
              label="Nome da Campanha"
              label-for="campaign-name"
          >
            <b-form-input
                id="campaign-name"
                v-model="name"
                required
            />
          </b-form-group>

          <!-- Período de Vigência -->
          <b-form-group label="Período de Vigência">
            <div class="d-flex">
              <b-form-datepicker
                  v-model="start_date"
                  placeholder="Data de Início"
              ></b-form-datepicker>
              <b-form-datepicker
                  v-model="end_date"
                  placeholder="Data de Fim"
                  class="ml-2"
              ></b-form-datepicker>
            </div>
          </b-form-group>

          <!-- Upload de Imagem -->
          <b-form-group
              label="Imagem da Capa"
              label-for="campaign-image"
          >
            <b-form-file
                id="campaign-image"
                v-model="imageFile"
                accept="image/*"
                @input="handleImageUpload"
            />
            <b-avatar
                v-if="previewImage"
                class="mt-2"
                :src="previewImage"
                rounded
                size="100px"
            ></b-avatar>
          </b-form-group>

          <!-- Descrição -->
          <b-form-group
              label="Descrição da Campanha"
              label-for="campaign-description"
          >
            <b-form-textarea
                id="campaign-description"
                v-model="details"
                placeholder="Digite a descrição"
                rows="3"
            />
          </b-form-group>

          <!-- Link para Regulamento -->
          <b-form-group
              label="Link para Regulamento"
              label-for="campaign-link"
          >
            <b-form-input
                id="campaign-link"
                v-model="link"
                placeholder="Digite o link"
                type="url"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                :disabled="saving"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
              {{ saving ? "Salvando..." : "Salvar" }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormFile, BAvatar, BButton, BFormInvalidFeedback, BFormTextarea
} from 'bootstrap-vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as types from '../store/types'
import {GET_CAMPAIGN_KPI} from "../store/types";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      alias: undefined,
      name: undefined,
      start_date: undefined,
      end_date: undefined,
      imageFile: null,
      previewImage: null,
      imageBase64: null,
      details: undefined,
      link: undefined,
    }
  },
  computed: {
    ...mapGetters({
      newCampaignKpiSidebar: types.NEW_CAMPAIGN_KPI_SIDEBAR
    })
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  methods: {
    ...mapMutations({
      mutateNewCampaignKpiSidebar: types.MUTATE_NEW_CAMPAIGN_KPI_SIDEBAR,
      mutateNewCampaignKpiArray: types.MUTATE_ADD_CAMPAIGN_KPI_ARRAY,
    }),
    ...mapActions({
      storeCampaign: types.STORE_CAMPAIGN_KPI,
      updateCampaign: types.UPDATE_CAMPAIGN_KPI,
      getCampaignKpi: types.GET_CAMPAIGN_KPI,
    }),
    clear(){
      this.alias = undefined
      this.name = null;
      this.start_date = null;
      this.end_date = null;
      this.imageFile = null;
      this.previewImage = null;
      this.imageBase64 = null;
      this.details = null;
      this.link = null;
      this.v$.$reset();
    },
    onShow() {
      if (this.newCampaignKpiSidebar.id != undefined) {
        this.loading = true;
        this.getCampaignKpi(this.newCampaignKpiSidebar.id)
            .then((resp) => {
              var data = resp.data;
              if (data) {
                this.name = data.name,
                    this.start_date = data.start_date,
                    this.end_date = data.end_date,
                    this.previewImage = data.image,
                    this.details = data.details,
                    this.link = data.link,
                    this.v$.$touch();
              }
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao carregar a tarefa para edição. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
    handleImageUpload() {
      const file = this.imageFile;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          this.imageBase64 = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async onSubmit() {
      this.saving = true

      const campaignData = {
        name: this.name,
        start_date: this.start_date,
        end_date: this.end_date,
        image: this.imageBase64,
        details: this.details,
        link: this.link,
      };

      if (this.newCampaignKpiSidebar.id != undefined) {
        campaignData.id = this.newCampaignKpiSidebar.id;

        this.updateCampaign(campaignData)
            .then(response => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: "A campanha foi atualizada com sucesso!",
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.mutateNewCampaignKpiSidebar({visible: false})
              this.mutateNewCampaignKpiArray(response.data.data)
              this.newCampaignKpiSidebar.saveAction()
            })
            .catch((err) => {
              if(err.response.status == 405){
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: err.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              } else if (err.response.status == 422 && err.response && err.response.data && err.response.data.errors) {
                const errorMessages = Object.values(err.response.data.errors)
                    .flat()
                    .join('\n');

                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: errorMessages,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              } else {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: "Ocorreu um erro ao atualizar a campanha. Entre em contato com o setor de TI.",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              }
            })
            .finally(() => {
              this.saving = false;
            });
      } else {
        this.storeCampaign(campaignData)
            .then(response => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: "A campanha foi criada com sucesso!",
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.mutateNewCampaignKpiSidebar({visible: false})
              this.newCampaignKpiSidebar.saveAction()
            })
            .catch((err) => {
              if (err.response.status == 405) {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: err.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              } else if (err.response.status == 422 && err.response && err.response.data && err.response.data.errors) {
                const errorMessages = Object.values(err.response.data.errors)
                    .flat()
                    .join('\n');

                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: errorMessages,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              } else {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: "Ocorreu um erro ao criar a campanha. Entre em contato com o setor de TI.",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              }
            })
            .finally(() => {
              this.saving = false;
            });
      }
    }
  }
}
</script>
