import * as types from './types'
import { 
  APP_BUSINESS_PARTNER_ROLE_PNC,
  APP_BUSINESS_PARTNER_ROLE_PNH,
  APP_BUSINESS_PARTNER_ROLE_PNP,
  APP_BUSINESS_PARTNER_ROLE_APRENDIZ,
  APP_BUSINESS_PARTNER_ROLE_FOCADO,
  APP_BUSINESS_PARTNER_ROLE_PROTAGONISTA,
  APP_BUSINESS_PARTNER_ROLE_CAMPEAO
} from '@/constants/auth'

export default {
  [types.USER]: state => {
    return state.user
  },
  [types.ABILITIES]: state => {
    return state.abilities
  },
  [types.IS_HUNTER]: state => {
    return state.user.user_role_id === APP_BUSINESS_PARTNER_ROLE_PNH
  },
  [types.IS_CLOSER]: state => {
    return state.user.user_role_id === APP_BUSINESS_PARTNER_ROLE_PNC
  },
  [types.IS_PLANNER]: state => {
    return (state.user.user_role_id === APP_BUSINESS_PARTNER_ROLE_PNP || state.user.user_role_id ===  APP_BUSINESS_PARTNER_ROLE_APRENDIZ)
  },
  [types.IS_PNS]: state => {
    return (
      state.user.user_role_id == APP_BUSINESS_PARTNER_ROLE_PNH ||
      state.user.user_role_id == APP_BUSINESS_PARTNER_ROLE_PNC ||
      state.user.user_role_id == APP_BUSINESS_PARTNER_ROLE_APRENDIZ || 
      state.user.user_role_id == APP_BUSINESS_PARTNER_ROLE_FOCADO || 
      state.user.user_role_id == APP_BUSINESS_PARTNER_ROLE_PROTAGONISTA || 
      state.user.user_role_id == APP_BUSINESS_PARTNER_ROLE_CAMPEAO
    )
  },
  [types.COMPANY_PENDENCIES]: state => {
    return state.company_pendencies
  },
  [types.SYSTEM_VERSION_NOTIFICATIONS]: state => {
    return state.announcement_notifications
  }
}
