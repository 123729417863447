import axios from 'axios'
import * as types from './types'

export default {
  [types.GET_EFFICIENCY]: ({ }, payload) => {
    return axios.get(`/api/financial_plannings/efficiency/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.SAVE_EFFICIENCY]: ({ }, payload) => {
    return axios.post(`/api/financial_plannings/efficiency/${payload.customer_id}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_CASH_FLOW_INCOME]: ({ }, payload) => {
    return axios.get(`/api/interviews/${payload}/cash_flow_income`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_FINANCIAL_MANAGEMENT]: ({ }, payload) => {
    return axios.get(`/api/financial_plannings/management/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.SAVE_FINANCIAL_MANAGEMENT]: ({ }, payload) => {
    return axios.post(`/api/financial_plannings/management/${payload.customer_id}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_RISKS]: ({ }, payload) => {
    return axios.get(`/api/financial_plannings/risks/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.SAVE_RISKS]: ({ }, payload) => {
    return axios.post(`/api/financial_plannings/risks/${payload.customer_id}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_SAVINGS]: ({ }, payload) => {
    return axios.get(`/api/financial_plannings/savings/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.SAVE_SAVINGS]: ({ }, payload) => {
    return axios.post(`/api/financial_plannings/savings/${payload.customer_id}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.OPEN_RISK_PROTECTION_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_RISK_PROTECTION_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.GET_RISK_PROTECTION_PRODUCTS]: ({ commit }, payload) => {
    return axios.get(`/api/financial_plannings/risks/protection/products`,)
      .then((resp) => {
        commit(types.MUTATE_RISK_PROTECTION_PRODUCTS, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_RISK_PROTECTION_COMPANIES]: ({ commit }, payload) => {
    return axios.get(`/api/financial_plannings/risks/protection/companies`,)
      .then((resp) => {
        commit(types.MUTATE_RISK_PROTECTION_COMPANIES, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_RISK_PROTECTION_COVERAGES]: ({ commit }, payload) => {
    return axios.get(`/api/financial_plannings/risks/protection/coverages`,)
      .then((resp) => {
        commit(types.MUTATE_RISK_PROTECTION_COVERAGES, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_RISK_PROTECTION_NEEDS]: ({ commit }, payload) => {
    return axios.get(`/api/financial_plannings/risks/protection/needs`,)
      .then((resp) => {
        commit(types.MUTATE_RISK_PROTECTION_NEEDS, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_RISK_PROTECTION]: ({ }, payload) => {
    return axios.get(`/api/financial_plannings/risks/protections/${payload.customer_id}/${payload.id}`,)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.SAVE_RISK_PROTECTION]: ({ }, payload) => {
    return axios.post(`/api/financial_plannings/risks/protections/${payload.customer_id}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_RISKS_PROTECTIONS]: ({ commit }, payload) => {
    return axios.get(`/api/financial_plannings/risks/protections/${payload}`)
      .then((resp) => {
        commit(types.MUTATE_RISKS_PROTECTIONS, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.SAVE_RISKS_PROTECTIONS]: ({ }, payload) => {
    return axios.post(`/api/financial_plannings/risks/protections/${payload.customer_id}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.REMOVE_RISK_PROTECTION]: ({ }, payload) => {
    return axios.delete(`/api/financial_plannings/risks/protections/${payload.customer_id}/${payload.id}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_FUTURE_EARNINGS]: ({ }, payload) => {
    return axios.get(`/api/financial_plannings/future_earnings/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.SAVE_FUTURE_EARNINGS]: ({ }, payload) => {
    return axios.post(`/api/financial_plannings/future_earnings/${payload.customer_id}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_ASSET_REBALANCING_METODOLOGIES]: ({ commit }, payload) => {
    return axios.get(`/api/financial_plannings/asset_management/rebalancing_metodologies`)
      .then((resp) => {
        commit(types.MUTATE_ASSET_REBALANCING_METODOLOGIES, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_ASSET_MANAGEMENT]: ({ }, payload) => {
    return axios.get(`/api/financial_plannings/asset_management/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.SAVE_ASSET_MANAGEMENT]: ({ }, payload) => {
    return axios.post(`/api/financial_plannings/asset_management/${payload.customer_id}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.OPEN_WALLET_INVESTMENT_GOALS_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_WALLET_INVESTMENT_GOALS_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.OPEN_INVESTMENT_PROFILE_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_INVESTMENT_PROFILE_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.GET_TAXES]: ({ }, payload) => {
    return axios.get(`/api/financial_plannings/taxes/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.SAVE_TAXES]: ({ }, payload) => {
    return axios.post(`/api/financial_plannings/taxes/${payload.customer_id}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.OPEN_IMPLEMENTATIONS_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_IMPLEMENTATIONS_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.GET_IMPLEMENTATIONS]: ({ commit }, payload) => {
    return axios.get(`/api/financial_plannings/implementations/${payload}`)
      .then((resp) => {
        commit(types.MUTATE_IMPLEMENTATIONS, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_IMPLEMENTATION]: ({ }, payload) => {
    return axios.get(`/api/financial_plannings/implementations/${payload.customer_id}/${payload.id}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.SAVE_IMPLEMENTATION]: ({ }, payload) => {
    return axios.post(`/api/financial_plannings/implementations/${payload.customer_id}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.REMOVE_IMPLEMENTATION]: ({ }, payload) => {
    return axios.delete(`/api/financial_plannings/implementations/${payload.customer_id}/${payload.id}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.FINISH_PLANNING]: ({ commit }, payload) => {
    return axios.get(`/api/financial_plannings/finish/${payload}`)
      .then((resp) => {
        commit(types.MUTATE_PLANNING_STATUS, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_PLANNING_STATUS]: ({ commit }, payload) => {
    return axios.get(`/api/financial_plannings/status/${payload}`)
      .then((resp) => {
        commit(types.MUTATE_PLANNING_STATUS, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.UPDATE_PLANNING_STATUS_CURRENT_STEP]: (_, payload) => {
    return axios.patch(`/api/financial_plannings/${payload.id}/status/${payload.step}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.EDIT_PLANNING]: ({ commit }, payload) => {
    return axios.get(`/api/financial_plannings/edit/${payload}`)
      .then((resp) => {
        commit(types.MUTATE_PLANNING_STATUS, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
  [types.GET_OBJECTIVES]: ({ commit }, payload) => {
    return axios.get(`/api/financial_plannings/objectives/${payload}`)
      .then((resp) => {
        commit(types.MUTATE_OBJECTIVES, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
    });
  },
}
