<template>
  <b-form-group
    v-if="url && !changeFile"
    :label="labelWithSuffix"
    :label-for="id"
  >
    <div :id="id">
      <h5>
        <a :href="url" target="_blank">
          {{ url | onlyFileName }}
        </a>
      </h5>
      <b-button
        size="sm"
        variant="outline-primary"
        @click.prevent="changeFile = true"
      >
        <feather-icon icon="EditIcon" class="mr-50" />
        <span>Alterar aquivo</span>
      </b-button>
    </div>
  </b-form-group>
  <b-form-group v-else :label="labelWithSuffix" :label-for="id">
    <b-form-file
      :disabled="disabled"
      :id="id"
      :accept="accept"
      :placeholder="placeholder"
      :value="value"
      :multiple="multiple"
      @input="handleInput"
      @change="handleChange"
      custom-file-label="Buscar"
      :class="{ 'is-invalid': thereIsError }"
    />
    <div class="invalid-feedback">
      <span v-if="thereIsError">
        {{ errorMessage || "Você deve anexar um arquivo." }}
      </span>
    </div>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormFile, BRow, BButton } from "bootstrap-vue";
import { VMoney } from "v-money";
export default {
  components: {
    BFormGroup,
    BFormFile,
    BRow,
    BButton,
  },
  directives: {
    money: VMoney,
  },
  props: {
    id: String,
    label: String,
    value: [File, Array],
    url: String,
    disabled: Boolean,
    onlyImages: Boolean,
    onlyDocuments: Boolean,
    onlyPDF: Boolean,
    onlySheets: Boolean,
    thereIsError: Boolean,
    errorMessage: String,
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      changeFile: false,
    };
  },
  computed: {
    accept: function () {
      if (this.onlyImages) {
        return "image/*";
      }
      if (this.onlyDocuments) {
        return "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      }
      if (this.onlyPDF) {
        return "application/pdf";
      }
      if(this.onlySheets) {
        return ".csv";
      }
      return "image/*, application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    },
    placeholder: function () {
      if (this.onlyImages) {
        return "Anexar imagem...";
      }
      if (this.onlyDocuments || this.onlyPDF) {
        return "Anexar documento...";
      }
      return "Anexar arquivo...";
    },
    labelWithSuffix: function () {
      if (this.onlyImages) {
        return `${this.label} (apenas imagens)`;
      }
      if (this.onlyDocuments) {
        return `${this.label} (apenas documentos)`;
      }
      if (this.onlyPDF) {
        return `${this.label} (apenas PDFs)`;
      }
      return `${this.label}`;
    },
  },
  methods: {
    handleInput(content) {
      this.$emit("input", content);
    },
    handleChange(content) {
      this.$emit("change", content);
    },
  },
};
</script>
